import React, { useState, useEffect, useRef } from "react";
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import { Link } from "react-router-dom";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
  // const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [dataOne, setDataOne] = useState({});
  const [dataTwo, setDataTwo] = useState({});
  const [dataTwo2, setDataTwo2] = useState({});

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchDataOne()]).then(
        (res) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchDataOne = async () => {
    getData("pageawards", "school")
      .then((response) => {
        if (response.status === "success") {
          setDataOne(response.data)
          // fetchDataNews(response.data.subtopic)
          // fetchDataNews2(45)
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  // const fetchDataNews = async (id) => {
  //   getData("news", id)
  //     .then((response) => {
  //       if (response.status === "success") {
  //         // setNewsData(response.data.recomment);
  //         setDataTwo(response.data.main);

  //         // console.log(response.data.recomment[0].id);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.response.data.error);
  //     });
  // };

  // const fetchDataNews2 = async (id) => {
  //   getData("news", id)
  //     .then((response) => {
  //       if (response.status === "success") {
  //         // setNewsData(response.data.recomment);
  //         setDataTwo2(response.data.main);

  //         // console.log(response.data.recomment[0].id);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.response.data.error);
  //     });
  // };

  // console.log(dataTwo)

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="capacity" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="pb-md-5 bg-top">
            <div className="container-fluid">
              <div className="row justify-content-center pb-md-5 text-start">
                <div className="col-12 col-md-12 col-lg-12 p-0">
                  <div className="">
                    {/* <img src={banner} className="w-100 rounded" /> */}
                    <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100" />
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                  <div className="font-60 weight-700">{dataOne.topic}</div>
                  <div className="mb-0 pt-3">
                    <div className="text-justify font-18 content-custom"
                      dangerouslySetInnerHTML={{
                        __html: dataOne.detail,
                      }}
                    />
                  </div>
                </div>
                {/* {dataOne ? */}
                <div className="col-12 col-md-12 col-lg-10 pb-5">
                  <div className="py-4 px-3 px-md-5">
                    <div className="mt-4 px-3">
                      <div className="row">
                        {/* <div className="col-12 col-md-12 col-lg-12">
                          <div className="sub-topic mt-1">
                            Training Course on Sufficiency Economy Philosophy towards Sustainability for School Principals/Directors and Teachers of SEAMEO Member Countries (2023)
                          </div>
                        </div> */}
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="py-3" style={{}}>
                            {/* <div
                              className="font-18 content text-justify"
                              style={{ lineHeight: "2" }}
                            >
                              <p>Activities under this programme kicked off with a three-phase training course.</p>
                              <p><span className="weight-700 mt-4">Phase 1 </span>of the training, which was completed on 28 September 2023, was conducted virtually and included one teacher and one principal from thirty-one schools from seven SEAMEO Member Countries. Over the four input sessions, the concept of Sufficiency Economy Philosophy (SEP) was explained and the close links between the Philosophy and the Sustainable Development Goals (SDGs) were highlighted and modelled. The identification of regional concepts and approaches that link to or complement SEP were welcomed during the discussions as these can contribute to the development of a co-created regional framework for sustainability.
                              </p>
                              <p style={{ lineHeight: "1" }}><br /></p>
                              <p>To complete phase 1, participants from each school are required to develop both a video that clearly presents their particular school environment/context together with a written proposal on how they plan to integrate the SEP principles and mindset to ensure sustainability is at the very heart of their school management and administration as well as the teaching and learning process. Based on these submissions, one school from each country will be invited to join the second phase of the training in Thailand in December 2023.
                              </p>
                              <p style={{ lineHeight: "1" }}><br /></p>
                              <p><span className="weight-700 mt-4">
                                Phase 2
                              </span> will give participants the opportunity to visit model schools and gain further insights which they can use to fine-tune their own proposals in consultation with SEAMEO SEPS’ expert team. In this way the participants will be ready to implement their proposals on their return to their home countries.
                              </p>
                              <p style={{ lineHeight: "1" }}><br /></p>
                              <p><span className="weight-700 mt-4">
                                Phase 3 {' '}
                              </span>
                                is the 6-month implementation period, after which participants have 3 months to prepare and submit a progress report, outlining what they have achieved, the challenges and the lessons learned.  These reports will be assessed by an expert committee according to fixed criteria. In addition, their various models will be shared among SEAMEO Member Countries on SEAMEO SEPS social media and as open access resources.
                              </p>
                            </div> */}

                            {dataOne.news && dataOne.news.length ?
                              <div>
                                <div className="topic mb-4">News</div>
                                <div className="row">
                                  {/* {dataNews.length ? dataNews.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => ( */}
                                  {dataOne.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                    <div
                                      className="col-12 col-md-12 col-lg-4"
                                      key={index}
                                    >
                                      <Link
                                        to={"/newsandgallery/news/" + val.newsId}
                                        className="a-news pb-4 mb-4 mb-md-5"
                                      >
                                        <div className="row text-start">
                                          <div className="col-12 col-md-12 col-lg-12">
                                            <div>
                                              <img
                                                src={
                                                  process.env.REACT_APP_IMG_LINK +
                                                  "/uploads/news/" +
                                                  val.newsId +
                                                  "/" +
                                                  val.banner
                                                }
                                                className="w-100 rounded"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-12 col-lg-12">
                                            <div className="font-14 mt-2">
                                              {moment(val.dateActive).format("D MMMM Y")}
                                            </div>

                                            <div className="news-topic mt-3 mt-md-0"
                                              dangerouslySetInnerHTML={{
                                                __html: val.topic,
                                              }}
                                            />
                                            {/* <div className="mt-1">
                                                                    <TagView myString={val.tag} />
                                                                </div> */}
                                            <div className="mt-4">
                                              <LinesEllipsis
                                                text={val.subTopic}
                                                maxLine="3"
                                                ellipsis="..."
                                                trimRight
                                                basedOn="letters"
                                              />
                                            </div>
                                            <div className="mt-3">
                                              <a style={{ color: "#e6c12f" }}>
                                                Read More
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              : null}
                            {/* <div className="mt-5">
                              <div className="news-topic">Previous Activities:</div>
                              <div>2023</div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* : null} */}
                </div>
              </div>
            </div>
          </section>

          <Footer page="activities" sub="capacity" />
        </div>
      </div>
    </>
  );
};

export default Main;

import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { menuData } from "../configs/menu";

import fb from '../assets/img/icon/FB.svg';
import yt from '../assets/img/icon/YT.svg';
import ig from '../assets/img/icon/IG.svg';
import linetop from '../assets/img/icon/LineTop.svg';

import { getData } from "../services/getService";

const Footer = (data) => {

    let history = useNavigate();

    // const { t } = useTranslation();
    const url = window.location.pathname;

    // const [menuactive, setMenuactive] = useState(false);

    const [memenu, setMemenu] = useState('0');
    const [menusubOpenB, setMenusubOpenB] = useState({ view: false, id: 0 });
    const [datacount, setDatacount] = useState('0');

    const handleMenusubChangeB = (state, id) => {
        if (menusubOpenB.id === id) {
            setMenusubOpenB({ view: !menusubOpenB.view, id: id });
        } else {
            setMenusubOpenB({ view: true, id: id });
        }
    };

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([getDataIp()]).then(
                (res) => {
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const getDataIp = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        // console.log(res.data);
        fetchData(res.data.ip)
    };

    const fetchData = async (iP) => {
        getData("visitordata", iP)
            .then((response) => {
                if (response.status === "success") {
                    setDatacount(response.data.count)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    // console.log(menusubOpenB)

    return (
        <>
            <div className='box-footer text-start pt-0 pt-md-0' >
                <div className='row pt-0 pt-md-0'>
                    <div className="col-12 col-md-12 col-lg-8 pt-0 pt-md-0 pt-lg-5">
                        <div className='row'>
                            <div className="col-12 col-md-3 col-lg-3 pt-3">

                                {menuData.filter(a => a.status === true && a.id === 2).map((val, index) => (
                                    <div key={index}>
                                        <div className={
                                            data.page === val.active
                                                ? "ps-2 ms-1 d-block text-start py-0 color-gray active-bold"
                                                : "ps-2 ms-1 d-block text-start py-0 color-gray"
                                        } >{val.name}</div>
                                        {val.submenu.filter(a => a.status === true).map((vall, indexx) => (
                                            <a href={vall.link} target={vall.target}
                                                className={
                                                    data.sub === vall.active ? 'btn btn-default d-block text-start py-0 active-bold'
                                                        : 'btn btn-default d-block text-start py-0'}
                                                key={indexx}>{vall.name}</a>
                                        ))}
                                    </div>

                                ))}
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pt-3">
                                {menuData.filter(a => a.status === true && a.id === 3).map((val, index) => (
                                    <div key={index}>
                                        <div className={
                                            data.page === val.active
                                                ? "ps-2 ms-1 d-block text-start py-0 color-gray active-bold"
                                                : "ps-2 ms-1 d-block text-start py-0 color-gray"
                                        } >{val.name}</div>
                                        {val.submenu.filter(a => a.status === true).map((vall, indexx) => (
                                            <a href={vall.link} target={vall.target}
                                                className={
                                                    data.sub === vall.active ? 'btn btn-default d-block text-start py-0 active-bold'
                                                        : 'btn btn-default d-block text-start py-0'}
                                                key={indexx}>{vall.name}</a>
                                        ))}
                                    </div>

                                ))}
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pt-3">
                                {/* <div className='ps-2 ms-1 d-block text-start py-0 color-gray'>Centre's Activities</div>
                                <a href='/' className='btn btn-default d-block text-start py-0'>Capacity Building</a>
                                <a href='/' className='btn btn-default d-block text-start py-0'>Community Development</a>
                                <a href='/' className='btn btn-default d-block text-start py-0'>Research & Development</a>
                                <a href='/' className='btn btn-default d-block text-start py-0'>Partnership & Linkages</a>
                                <a href='/' className='btn btn-default d-block text-start py-0'>Sharing & Learining</a>
                                <a href='/' className='btn btn-default d-block text-start py-0'>Awards</a> */}
                                {menuData.filter(a => a.status === true && a.id === 4).map((val, index) => (
                                    <div key={index}>
                                        <div className={
                                            data.page === val.active
                                                ? "ps-2 ms-1 d-block text-start py-0 color-gray active-bold"
                                                : "ps-2 ms-1 d-block text-start py-0 color-gray"
                                        } >{val.name}</div>
                                        {val.submenu.filter(a => a.status === true).map((vall, indexx) => (


                                            vall.supersubmenu.length ?
                                                <li className="companymenuli ms-1 ps-2" key={indexx}>
                                                    <a className={data.sub === vall.active ? "alisting active" : "alisting" }>{vall.name}</a>
                                                    <div className="submenu">
                                                        <ul >
                                                            {vall.supersubmenu.filter(a => a.status === true).map((valll, indexxx) => (
                                                                <li key={indexxx}><a href={valll.link} target={valll.target}>{valll.name}</a></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </li>
                                                :
                                                <a href={vall.link} target={vall.target}
                                                    className={
                                                        data.sub === vall.active ? 'btn btn-default d-block text-start py-0 active-bold'
                                                            : 'btn btn-default d-block text-start py-0'}
                                                    key={indexx}>{vall.name}</a>
                                        ))}

                                    </div>

                                ))}
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pt-3">
                                {menuData.filter(a => a.status === true && a.id === 6).map((val, index) => (
                                    <div key={index}>
                                        <div className={
                                            data.page === val.active
                                                ? "ps-2 ms-1 d-block text-start py-0 color-gray active-bold"
                                                : "ps-2 ms-1 d-block text-start py-0 color-gray"
                                        } >{val.name}</div>
                                        {val.submenu.filter(a => a.status === true).map((vall, indexx) => (
                                            <a href={vall.link} target={vall.target}
                                                className={
                                                    data.sub === vall.active ? 'btn btn-default d-block text-start py-0 active-bold'
                                                        : 'btn btn-default d-block text-start py-0'}
                                                key={indexx}>{vall.name}</a>
                                        ))}
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 pt-md-0 pt-lg-5">
                        <div className='mb-5 px-3 px-md-3 px-lg-0 pt-3 pt-md-2'>
                            <div className='news-topic'>ADDRESS</div>
                            <div className='weight-500 font-18'>
                                11th Floor, Nature and Environment Building <br />
                                928, Sukhumvit Road, Phra Khanong, Khlong Toei, <br />Bangkok, 10110 Thailand
                            </div>
                            {/* <div className='my-4'>
                                <Link target={'_blank'} to="https://www.google.com/maps/place/SEAMEO+SEPS/@13.7210162,100.5824978,17z/data=!4m6!3m5!1s0x30e29f79e88743bf:0x86d6ad3a0c6befb7!8m2!3d13.7210737!4d100.5822646!16s%2Fg%2F11spvr1wxs?entry=ttu" className='btn btn-see py-1'>
                                    Direction Google map
                                </Link>
                            </div> */}
                            <div className='weight-500 font-18 mt-4'>
                                E-mail : <a href='mailto:secretariat@seameoseps.org' className='a-null text-decoration-underline'>secretariat@seameoseps.org</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className='px-3'>
                            <img src={linetop} alt="" className="w-100" style={{ height: '2px' }} />
                        </div >
                        <div className='weight-500 px-3 pb-3'>
                        <a href='https://www.facebook.com/seameoseps' className='d-inline-block p-0' target="_blank" ><img src={fb} alt="" style={{ height: "30px" }} /></a>
                            <a href='https://www.youtube.com/@seameoseps' className='d-inline-block px-2' target="_blank"><img src={yt} alt="" style={{ height: "30px" }} /></a>
                            <a href='https://www.instagram.com/seameoseps' className='d-inline-block p-0' target="_blank"><img src={ig} alt="" style={{ height: "30px" }} /></a>
                            <span className='float-end d-none d-md-block'>Visitor : {datacount} | © 2023 by SEAMEO SEPS - All rights reserved.</span>
                        </div>
                        <div className='d-block d-md-none font-12 text-center'>
                            Visitor : {datacount} | © 2023 by SEAMEO SEPS - All rights reserved.
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Footer;